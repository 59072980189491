<template>
  <div style="margin: 30px">
    <div>
      <img
        src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
        style="max-width: 7%"
        @click="returnDashboard()"
      />
      <h1>Notifikasi</h1>
    </div>
    <br />
    <b-row v-for="notification in notifications">
      <b-col
        cols="2"
        style="width: 9%; display: flex; align-items: center; flex-wrap: wrap"
        v-if="notification.is_read == false"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/informationicon_NWuHSLW2qo.png?updatedAt=1695115435306"
          style="max-width: 200%"
      /></b-col>
      <b-col
        cols="2"
        style="width: 9%; display: flex; align-items: center; flex-wrap: wrap"
        v-if="notification.is_read == true"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/informationicon02_OrQ42gpaWE.png?updatedAt=1696919824779"
          style="max-width: 200%"
      /></b-col>
      <b-col
        cols="10"
        style="width: 91%; padding-top: 10px; padding-bottom: 10px"
        v-if="notification.is_read == false"
        ><h3>{{ notification.notification_message }}</h3></b-col
      >
      <b-col
        cols="10"
        style="
          width: 91%;
          padding-top: 10px;
          padding-bottom: 10px;
          color: #b9b9b9;
        "
        v-if="notification.is_read == true"
        ><h3>{{ notification.notification_message }}</h3></b-col
      >
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    returnDashboard() {
      this.$router.push("/Dashboard");
    },
  },
  created() {
    let date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let date2 = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    let start = "";
    let end = "";
    if (date.getMonth() + 1 < 10) {
      start = `${date.getFullYear()}-0${
        date.getMonth() + 1
      }-0${date.getDate()}`;
    } else {
      start = `${date.getFullYear()}-${date.getMonth() + 1}-0${date.getDate()}`;
    }
    if (date2.getMonth() + 1 < 10) {
      end = `${date2.getFullYear()}-0${
        date2.getMonth() + 1
      }-${date2.getDate()}`;
    } else {
      end = `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`;
    }
    axios({
      url: `${process.env.VUE_APP_API_URL}/api/v1/notification/show`,
      method: "post",
      headers: {
        Authorization: "Bearer " + localStorage.earnAccessToken,
      },
      data: {
        customer_uuid: `${localStorage.earnCustUUID}`,
        first_period: start,
        last_period: end,
      },
    })
      .then((res) => {
        this.notifications = res.data.data;
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });
  },
};
</script>

<style scoped></style>
