<!-- eslint-disable -->
<template>
  <div style="margin: 10px 20px 10px 20px">
    <img
      src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
      style="max-width: 7%"
      @click="returnDashboard()"
    />
    <h1>Invoice</h1>
    <div>
      <img
        src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pln_F7nZkNenO.png?updatedAt=1721748123057"
        alt=""
        style="max-width: 10%"
      />
      <h2 style="display: inline-block">Listrik PLN</h2>
    </div>
    <br />
    <b-row>
      <b-col cols="6"><h2>Nomor</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ invoiceNumber }}</h3></b-col
      >
    </b-row>
    <b-row style="background-color: #f8f8f8">
      <b-col cols="6"><h2>Tanggal</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ date }}</h3></b-col
      >
    </b-row>
    <b-row>
      <b-col cols="6"><h2>status</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ status }}</h3></b-col
      >
    </b-row>
    <b-row style="background-color: #f8f8f8">
      <b-col cols="6"><h2>No. Pelanggan</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ meterId }}</h3></b-col
      >
    </b-row>
    <b-row>
      <b-col cols="6"><h2>Bayar</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ totalFee }}</h3></b-col
      >
    </b-row>
    <b-row style="background-color: #f8f8f8">
      <b-col cols="6"><h2>Token</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ token }}</h3></b-col
      >
    </b-row>
    <hr style="margin: 0 0 10px 0" />
    <br />
    <br />
    <button @click="returnDashboard()" class="continueBtn" style="width: 100%">
      Kembali ke menu utama
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoiceNumber: "XYZ/123456/PULSA100K",
      meterId: "",
      date: "",
      status: "Transaksi Berhasil",
      totalFee: "",
      token: "1258 2346 2589 4589 1187",
    };
  },
  methods: {
    returnDashboard() {
      this.$router.push({ path: "Dashboard" });
    },
  },
  created() {
    let invoiceData = JSON.parse(localStorage.invoicePLN);
    this.totalFee = invoiceData.totalFee;
    this.meterId = invoiceData.meterId;
    let day = new Date(invoiceData.date).getDate();
    let month = Intl.DateTimeFormat("id", { month: "long" }).format(
      new Date(invoiceData.date)
    );
    let year = new Date(invoiceData.date).getFullYear();
    this.date = `${day} ${month} ${year}`;
  },
};
</script>

<style>
input:focus {
  outline: none;
}
</style>
