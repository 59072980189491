<template>
  <div style="margin: 10px 20px 10px 20px">
    <img
      src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
      style="max-width: 7%"
      @click="returnDashboard()"
    />
    <h1>Voucher Gift</h1>
    <form>
      <h4>Masukkan kode voucher:</h4>
      <b-row>
        <b-col cols="8">
          <input
            v-model="code"
            @input="formattedCode(code)"
            type="text"
            maxlength="10"
            placeholder="masukkan kode voucher"
            style="width: 80%; border: none; padding: 10px"
          />
        </b-col>
        <b-col cols="4">
          <h3
            style="
              color: #5f7af7;
              text-align: right;
              margin-bottom: 10px;
              width: 100%;
            "
            @click.prevent="checkVoucher()"
          >
            Cek sekarang
          </h3>
        </b-col>
      </b-row>
      <hr style="margin: 0 0 10px 0" />
      <h3 v-if="info == 'emptyField'" style="color: red">
        Mohon masukkan kode voucher
      </h3>
      <h3 v-else-if="info == 'error'" style="color: red">
        {{ message }}
      </h3>
      <h3 v-else-if="info == 'ok'">
        {{ message }}
      </h3>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <button
        :class="{ continueBtn: verifyCode, grayBtn: !verifyCode }"
        style="margin-bottom: 10px; width: 100%"
        @click.prevent="takeVoucher(code)"
      >
        lanjutkan
      </button>
      <br />
      <br />
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  data() {
    return {
      code: "",
      customerData: {},
      // flatAdminFee: 0,
      // adminFee: 0,
      // minFeeMaxAmount: 0,
      // feeAmountPercent : 0,
      // amountReceived: 0,
      verifyCode: false,
      info: "",
      message: "",
      nominal: 0,
    };
  },
  created() {
    this.customerData = JSON.parse(localStorage.earn);
    this.$store.dispatch("getFee", "eats");
    // setTimeout(() => {
    //     this.flatAdminFee = this.$store.state.flatAdminFee
    //     this.minFeeMaxAmount = this.$store.state.minFeeMaxAmount
    //     this.feeAmountPercent =  this.$store.state.feeAmountPercent
    // }, 500)
  },
  methods: {
    checkVoucher() {
      if (!this.code.length) {
        this.info = "emptyField";
      } else {
        axios({
          url: `${process.env.VUE_APP_API_URL}/api/v1/redeem/${this.code}`,
          method: "get",
          headers: {
            Authorization: "Bearer " + localStorage.earnAccessToken,
          },
        })
          .then((res) => {
            this.redeemUUID = res.data.data.redeem_code_id;
            this.info = "ok";
            this.nominal = res.data.data.amount;
            this.message = `Voucher senilai Rp ${Intl.NumberFormat(
              "id-ID"
            ).format(res.data.data.amount.replace(/[,.]/g, ""))} aktif`;
            this.verifyCode = true;
          })
          .catch((err) => {
            this.info = "error";
            if (
              err.response.data.data.voucher_unavailable_message ==
              "Full authentication is required to access this resource"
            ) {
              this.message =
                "Maaf, fitur ini belum tersedia. Silahkan kembali setelah beberapa saat";
            } else {
              this.message = err.response.data.data.voucher_unavailable_message;
            }
            this.verifyCode = false;
          });
      }
    },
    takeVoucher() {
      let payload = {
        code: this.code,
        nominal: this.nominal,
        codeUUID: this.redeemUUID,
      };
      this.$store.dispatch("storeClaimVoucher", payload);
    },
    returnDashboard() {
      this.$router.push("/Dashboard");
    },
  },
  computed: {
    formattedCode() {
      this.info = "";
      this.message = "";
      this.verifyCode = false;
      this.code = this.code.toUpperCase();
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  font-size: 15px;
}
input:focus {
  border: none;
  outline: none;
}
</style>

<style>
.swal2-actions {
  margin: 10px 0;
}

.swal2-styled {
  width: 90% !important;
}
</style>
