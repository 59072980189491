<template>
    <div style="text-align: center; padding-left: 20px; padding-right: 20px;">
        <br>
        <br>
        <br>
        <img src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20comingsoon_i81ZcYukh.png?updatedAt=1701941318623" style="max-width: 50%;"> <br><br>
        <h1>Sabar ya...</h1>
        <h3>Fitur ini masih dalam tahap pengembangan</h3>
        <br>
        <br>
        <button class="continueBtn" style="width: 100%;" @click="returnDashboard()">Kembali ke menu awal</button>
    </div>
</template>

<script>
export default {
    methods: {
        returnDashboard(){
            this.$router.push('/Dashboard')
        }
    }
};

</script>

<style scoped>

</style>