import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/index.js";
import Swal from "sweetalert2";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    balance: 0,
    mutation: 0,
    flatAdminFee: 0,
    minFeeMaxAmount: 0,
    feeAmountPercent: 2,
    withdrawalNett: 0,
    clearBusy: false,
    mutationPLN: {},
    invoicePLN: {},
    PLNStatus: [],
    voucher: "",
    nominal: 0,
    codeUUID: "",
  },
  mutations: {
    STORE_MUTATION(state, payload) {
      if (typeof payload == "string") {
        state.mutation = JSON.parse(payload).amount;
      } else {
        state.mutation = payload.amount;
      }
      // state.adminFee = payload.adminFee
      // state.withdrawalNett = payload.amountReceived
    },
    CHECKOUT(state) {
      let data = JSON.parse(localStorage.earn);
      data.balance.balance_left_amount =
        data.balance.balance_left_amount - state.mutation;
      state.balance = data.balance.balance_left_amount;
      data.balance.balance_used_amount =
        data.balance.balance_used_amount + state.mutation;
      localStorage.setItem("earn", JSON.stringify(data));
      state.mutation = 0;
      localStorage.setItem("withdrawAmount", 0);
    },
    STORE_BALANCE(state, payload) {
      state.balance = payload;
      state.clearBusy = true;
    },
    STORE_FEE(state, payload) {
      (state.flatAdminFee = payload.min_fee_amount),
        (state.minFeeMaxAmount = payload.min_fee_amount_for_withdraw),
        (state.feeAmountPercent = payload.fee_amount_percent);
    },
    STORE_MUTATION_PLN(state, payload) {
      state.mutationPLN = payload;
    },
    CLEAR_BUSY_FALSE(state) {
      state.clearBusy = false;
    },
    CLEAR_BUSY_TRUE(state) {
      state.clearBusy = true;
    },
    STORE_INVOICE_PLN(state, payload) {
      state.invoicePLN = payload;
    },
    STORE_PLNSTATUS(state, payload) {
      state.PLNStatus = payload;
    },
    STORE_CLAIM_VOUCHER(state, payload) {
      let data = {};
      if (typeof payload == "string") {
        data = JSON.parse(payload);
      } else {
        data = payload;
      }
      state.voucher = data.code;
      state.nominal = data.nominal;
      state.codeUUID = data.codeUUID;
      localStorage.setItem("voucher", JSON.stringify(data));
    },
  },
  actions: {
    getFee({ commit }, payload) {
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/loan/get_fee`,
        method: "post",
        headers: {
          Authorization: "Bearer " + localStorage.earnAccessToken,
        },
        data: {
          thirdparty_id: payload,
        },
      })
        .then((res) => {
          localStorage.setItem("earnFee", JSON.stringify(res.data.data));
          commit("STORE_FEE", res.data.data);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    },
    storeMutation({ commit }, payload) {
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/loan/get_fee`,
        method: "post",
        headers: {
          Authorization: "Bearer " + localStorage.earnAccessToken,
        },
        data: {
          thirdparty_id: payload,
        },
      })
        .then((res) => {
          commit("STORE_MUTATION", res.data.data);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    },
    storeFinalMutation({ commit }, payload) {
      commit("STORE_MUTATION", payload);
      if (typeof payload == "string") {
        localStorage.setItem("earnWithdraw", payload);
      } else {
        localStorage.setItem("earnWithdraw", JSON.stringify(payload));
      }
      if (router.currentRoute.path !== "/Confirmation") {
        router.push({ path: "Confirmation" });
      }
    },
    balanceMutation({ commit }, payload) {
      commit("CLEAR_BUSY_FALSE");
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/loan/new_loan`,
        method: "post",
        headers: {
          Authorization: "Bearer " + localStorage.earnAccessToken,
        },
        data: {
          user_uuid: localStorage.earnCustUUID,
          entered_pin: payload,
          loan_amount: this.state.mutation,
          amount_advance: this.state.mutation,
        },
      })
        .then(() => {
          axios({
            url: `${process.env.VUE_APP_API_URL}/api/v1/customer/${localStorage.earnCustUUID}/account`,
            method: "get",
            headers: {
              Authorization: "Bearer " + localStorage.earnAccessToken,
            },
          })
            .then((res) => {
              commit(
                "STORE_BALANCE",
                res.data.data.balance.balance_left_amount
              );
              localStorage.setItem("earn", JSON.stringify(res.data.data));
            })
            .catch((err) => {
              router.push({ path: "NotFound" });
            });
          commit("CHECKOUT");
          Swal.fire({
            imageUrl:
              "https://ik.imagekit.io/44d0h1g5ht2/Atoer/requestsenticon_PI_8fIRig.png?updatedAt=1701929551963",
            imageWidth: 50,
            imageHeight: 50,
            text: "Permintaan anda telah diterima dan diproses",
          }).then(() => {
            router.push({ path: "Dashboard" });
          });
        })
        .catch((err) => {
          commit("CLEAR_BUSY_TRUE");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    },
    storeBalance({ commit }, payload) {
      commit("STORE_BALANCE", payload);
    },
    storeMutationPLN({ commit }, payload) {
      localStorage.setItem("earnMutationPLN", JSON.stringify(payload));
      commit("STORE_MUTATION_PLN", payload);
      if (router.currentRoute.path !== "/CheckoutPLN") {
        router.push({ path: "CheckoutPLN" });
      }
    },
    storeInvoicePLN({ commit }, payload) {
      commit("STORE_INVOICE_PLN", payload);
      localStorage.setItem("invoicePLN", JSON.stringify(payload));
      if (router.currentRoute.path !== "/InvoicePLN") {
        router.push({ path: "InvoicePLN" });
      }
    },
    PLNStatus({ commit }, payload) {
      commit("STORE_PLNSTATUS", payload);
      if (router.currentRoute.path !== "/PLN") {
        router.push({ path: "PLN" });
      }
    },
    storeClaimVoucher({ commit }, payload) {
      commit("STORE_CLAIM_VOUCHER", payload);
      if (router.currentRoute.path !== "/ClaimVoucher") {
        router.push({ path: "ClaimVoucher" });
      }
    },
    storeVoucherCode({ commit }, payload) {
      commit("CLEAR_BUSY_FALSE");
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/redeem/create-request`,
        method: "post",
        headers: {
          Authorization: "Bearer " + localStorage.earnAccessToken,
        },
        data: payload,
      })
        .then((res) => {
          commit("CLEAR_BUSY_TRUE");
          Swal.fire({
            imageUrl:
              "https://ik.imagekit.io/44d0h1g5ht2/Atoer/requestsenticon_PI_8fIRig.png?updatedAt=1701929551963",
            imageWidth: 50,
            imageHeight: 50,
            text: res.data.message,
          }).then(() => {
            router.push({ path: "Dashboard" });
          });
        })
        .catch((err) => {
          commit("CLEAR_BUSY_TRUE");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    },
  },
});
