<template>
  <div>
    <div class="topside">
      <div style="margin-left: 7%">
        <br />
        <img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/Atoer-TEXT-white_XF5-ZZfe_.png?updatedAt=1701929588218"
          style="max-width: 22%"
        />
        <img
          v-b-toggle.sidebar-right
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/menuicon_rb_QVbHN_w.png?updatedAt=1701929539596"
          style="
            position: absolute;
            top: 30px;
            right: 30px;
            max-width: 5%;
            cursor: pointer;
          "
        />
        <h3
          style="
            color: white;
            line-height: 5px;
            padding-top: 5px;
            padding-bottom: 25px;
          "
        >
          Hi, {{ name }}!
        </h3>
      </div>
      <div class="available-fund">
        <b-row style="margin: 5px 0">
          <b-col cols="6"
            ><h2 style="color: #3d3d3d">
              <strong>Saldo tersedia</strong>
            </h2></b-col
          >
          <b-col cols="6" style="text-align: right; color: #3d3d3d"
            ><h2>
              <strong
                >Rp.
                {{
                  limit
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                }}</strong
              >
            </h2></b-col
          >
        </b-row>
        <hr style="margin-top: 0" />
        <b-row>
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="customWithdraw()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-penarikan_HaSImJDPz.png?updatedAt=1701680081545"
              width="25"
              height="25"
            /><br />
            <h3>Penarikan</h3></b-col
          >
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="ComingSoon()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-tagihan_scZeO5GmD.png?updatedAt=1701941334777"
              width="25"
              height="25"
            /><br />
            <h3>Top Up & Tagihan</h3></b-col
          >
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="toVoucher()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20voucher_6EiTSgS9Y.png?updatedAt=1725879212090"
              width="25"
              height="25"
            /><br />
            <h3>Gift Voucher</h3></b-col
          >
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="ComingSoon()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20bayar_4mFvqWFHq.png?updatedAt=1701941334817"
              width="25"
              height="25"
            /><br />
            <h3>Bayar</h3></b-col
          >
        </b-row>
        <b-row>
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="Calendar()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20periode_Gwl9u7S8T.png?updatedAt=1701680081596"
              width="35"
              height="35"
            /><br />
            <h3>Periode Gaji</h3></b-col
          >
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="toNotification()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20notifikasi_5k-VkueVb.png?updatedAt=1701680081437"
              width="35"
              height="35"
            /><br />
            <h3>Notifikasi</h3></b-col
          >
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="toHistory()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20sejarah_GR1uJYcPp.png?updatedAt=1701680081406"
              width="35"
              height="35"
            /><br />
            <h3>Riwayat</h3></b-col
          >
          <b-col
            cols="3"
            style="color: #3d3d3d; text-align: center"
            @click="toTnC()"
            ><img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20syaratK_3QuFCosp8.png?updatedAt=1701941334466"
              width="35"
              height="35"
            /><br />
            <h3>Syarat & Ketentuan</h3></b-col
          >
        </b-row>
        <div class="dashboard-banner">
          <img
            src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/banner01_dPGOgOt6ua.png?updatedAt=1701929574010"
            width="100%"
            height="100%"
          />
        </div>
        <div class="scroll-container">
          <img
            src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/banner02_481n3mCIH.png?updatedAt=1702285482758"
            height="26%"
          />
          <img
            src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/banner03_1RApbc086.png?updatedAt=1702285482680"
            height="26%"
          />
          <img
            src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/banner05_XS8GSp4nP.png?updatedAt=1721748123069"
            height="26%"
          />
        </div>
      </div>
    </div>
    <b-sidebar id="sidebar-right" title="Sidebar" right shadow no-header>
      <CustomSidebar />
    </b-sidebar>
    <Footer />
  </div>
</template>

<script>
import CustomSidebar from "../components/CustomSidebar.vue";
import Footer from "../components/Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  components: {
    CustomSidebar,
    Footer,
  },
  data() {
    return {
      sufficient100: true,
      sufficient500: true,
      sufficient1000: true,
      name: "",
      limit: 0,
      startPeriod: "",
      endPeriod: "",
    };
  },
  methods: {
    customWithdraw() {
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/loan/${localStorage.earnCustUUID}/withdraw_status`,
        method: "get",
        headers: {
          Authorization: "Bearer " + localStorage.earnAccessToken,
        },
      })
        .then(() => {
          if (this.limit >= 100000) {
            this.$router.push({ path: "CustomWithdraw" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Maaf, saldo anda kurang dari Rp.100.000,00. Anda tidak bisa melakukan penarikan saldo dibawah Rp. 100.000,00",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    },
    bills() {
      this.$router.push({ path: "Bills" });
    },
    toHistory() {
      this.$router.push({ path: "History" });
    },
    toNotification() {
      this.$router.push({ path: "Notification" });
    },
    toTnC() {
      this.$router.push({ path: "TnC" });
    },
    toVoucher() {
      this.$router.push({ path: "InputVoucher" });
    },
    ComingSoon() {
      this.$router.push({ path: "Maintenance" });
    },
    Calendar() {
      this.$router.push({ path: "Calendar" });
    },
  },
  created() {
    let balance = JSON.parse(localStorage.earn).balance
      .current_available_balance; //ambil dri localStorage yang dibuat di page home
    if (balance <= 100000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Maaf, saldo anda kurang dari Rp.100.000,00. Anda tidak bisa melakukan penarikan saldo dibawah Rp. 100.000,00",
      });
    }
    this.$store.dispatch("storeBalance", balance); //simpen available balance di store
    this.limit = this.$store.state.balance; //ambil available balance dri store untuk ditampilkan
    this.name = JSON.parse(localStorage.earn).full_name.split(" ")[0]; //ambil nama pertama dari full_name
    let firstPeriod = JSON.parse(localStorage.earn)
      .salary_first_period.split("-")
      .reverse(); //array ([year],[month],[date])
    let startDate = `${Number(firstPeriod[0])} ${Intl.DateTimeFormat("id", {
      month: "long",
    }).format(new Date(firstPeriod[1]))}`; //format dd-MMM for start date
    let lastPeriod = JSON.parse(localStorage.earn)
      .salary_last_period.split("-")
      .reverse(); //array ([year],[month],[date])
    let endDate = `${Number(lastPeriod[0])} ${Intl.DateTimeFormat("id", {
      month: "long",
    }).format(new Date(lastPeriod[1]))}`; //format dd-MMM for finish date
    // let finishPeriod = new Date(Number(lastPeriod[2]),Number(lastPeriod[1]),Number(lastPeriod[2])) //full date to count offset
    // let finishOffset = finishPeriod.setTime(finishPeriod.getTime() - ((24*60*60*1000) * 2)) //count in integer for 2 offset days
    // let offsetDate = new Date(finishOffset) //offset date
    this.startPeriod = startDate;
    this.endPeriod = endDate;
    // this.limitPeriod = offsetDate
    // let today = new Date()
    if (new Date().getHours() == 23 || new Date().getHours() < 8) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Waktu saat ini diluar jam operasional kantor. Dana akan dicairkan pada jam operasional kantor...",
      });
    }
    // if (today > 27) {
    //   this.limit = 0
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Sudah melewati batas tanggal untuk periode bulan ini! Coba lagi di tanggal 1 bulan berikutnya yaa...',
    //   })
    // }else
    // if (today > this.limitPeriod) {
    //   this.limit = 0
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Anda tidak bisa menarik dana pinjaman 2 hari sebelum periode berakhir',
    //   })
    // }else {
    //   this.limit = this.$store.state.balance;
    // }
    if (this.limit < 1000000) {
      this.sufficient1000 = false;
    }
    if (this.limit < 500000) {
      this.sufficient500 = false;
    }
    if (this.limit < 100000) {
      this.sufficient100 = false;
    }
  },
};
</script>

<style>
.topside {
  background-color: #5f7af7;
}

div.scroll-container {
  overflow: auto;
  white-space: nowrap;
}

div.scroll-container img {
  padding: 2px;
}
</style>

<style scoped>
.row > {
  margin-left: 0;
  margin-right: 0;
}
</style>
