<template>
  <div style="margin: 30px">
    <div>
      <img
        src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
        style="max-width: 7%"
        @click="returnDashboard()"
      />
      <h1>Top-Up & Tagihan</h1>
      <br />
      <img
        src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/banner05_XS8GSp4nP.png?updatedAt=1721748123069"
        style="max-width: 100%"
      />
    </div>
    <br />
    <b-row>
      <b-col cols="3" style="color: #3d3d3d; text-align: center" @click="PLN()"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pln_F7nZkNenO.png?updatedAt=1721748123057"
          width="25"
          height="25"
        /><br />
        <h3>Listrik PLN</h3></b-col
      >
      <b-col
        cols="3"
        style="color: #3d3d3d; text-align: center"
        @click="ComingSoon()"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pulsa_A-5NWahll.png?updatedAt=1721748123089"
          width="25"
          height="25"
        /><br />
        <h3>Pulsa</h3></b-col
      >
      <b-col
        cols="3"
        style="color: #3d3d3d; text-align: center"
        @click="ComingSoon()"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pulsa_A-5NWahll.png?updatedAt=1721748123089"
          width="25"
          height="25"
        /><br />
        <h3>Kuota Internet</h3></b-col
      >
      <b-col
        cols="3"
        style="color: #3d3d3d; text-align: center"
        @click="ComingSoon()"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pulsa_A-5NWahll.png?updatedAt=1721748123089"
          width="25"
          height="25"
        /><br />
        <h3>Pascabayar</h3></b-col
      >
    </b-row>
    <b-row>
      <b-col cols="3" style="color: #3d3d3d; text-align: center" @click="PDAM()"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pdam_NrjXeTSgG.png?updatedAt=1721748122992"
          width="25"
          height="25"
        /><br />
        <h3>PDAM</h3></b-col
      >
      <b-col
        cols="3"
        style="color: #3d3d3d; text-align: center"
        @click="telephone()"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pulsa_A-5NWahll.png?updatedAt=1721748123089"
          width="25"
          height="25"
        /><br />
        <h3>Telepon rumah</h3></b-col
      >
      <b-col
        cols="3"
        style="color: #3d3d3d; text-align: center"
        @click="internet()"
        ><img
          src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-internet_M_sQHv7kn.png?updatedAt=1721748122680"
          width="25"
          height="25"
        /><br />
        <h3>Internet & TV</h3></b-col
      >
    </b-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  methods: {
    returnDashboard() {
      this.$router.push("/Dashboard");
    },
    PLN() {
      let PPOBStatus = JSON.parse(localStorage.PPOBStatus);
      // PPOBStatus.forEach((element) => {
      //   if (element.name == "Listrik PLN" && element.is_active) {
      //     axios({
      //       url: `${process.env.VUE_APP_API_URL}/api/v1/ppob/get_ppob/${element.id}`,
      //       method: "get",
      //       headers: {
      //         Authorization: "Bearer " + localStorage.earnAccessToken,
      //       },
      //     })
      //       .then((res) => {
      //         this.$store.dispatch("PLNStatus", res.data);
      //       })
      //       .catch((err) => {
      //         Swal.fire({
      //           icon: "error",
      //           title: "Oops...",
      //           text: err.response.data.message,
      //         });
      //       });
      //   } else if (element.name == "Listrik PLN" && !element.is_active) {
      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: "Maaf, saat ini layanan pembayaran listrik PLN sedang di non-aktifkan",
      //     });
      //   }
      // });
    },
    PDAM() {
      this.$router.push({ path: "Maintenance" });
    },
    telephone() {
      this.$router.push({ path: "Maintenance" });
    },
    internet() {
      this.$router.push({ path: "Maintenance" });
    },
    ComingSoon() {
      this.$router.push({ path: "Maintenance" });
    },
  },
};
</script>

<style scoped></style>
