<template>
    <div style="margin: 10px 20px 10px 20px;">
        <img src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095" style="max-width: 7%;" @click="returnDashboard()">
        <h1>Penarikan</h1>
        <form>
            <b-row>
                <b-col cols="6"><h2>Saldo tersedia</h2></b-col>
                <b-col cols="6" style="text-align: right; color: #3d3d3d;;"><h2>Rp. {{balance.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}}</h2>
                </b-col>
            </b-row>
            <h4>Jumlah penarikan:</h4>
            <b-row>
                <b-col cols="8">
                    <h3 style="display: inline-block;">Rp.</h3>
                    <input v-model="amount" @input="formattedPrice(amount)" type="text" maxlength="11" inputmode="numeric" placeholder="min. Rp. 100.000" style="width: 80%; border: none; padding: 10px;">
                </b-col>
                <b-col cols="4">
                    <h3 style="color: #5f7af7; text-align: right; margin-bottom: 10px; width: 100%;" @click.prevent="takeAll()">Tarik semua</h3> 
                </b-col>
            </b-row>
            <hr style="margin: 0 0 10px 0;">
            <h4>Informasi penarikan:</h4>
            <b-row>
                <b-col cols="6"><h2>Nama Bank</h2></b-col>
                <b-col cols="6" style="text-align: right;"><h3>{{ customerData.bank.code }}</h3></b-col>
            </b-row>
            <b-row style="background-color: #F8F8F8;">
                <b-col cols="6"><h2>Nomor rekening</h2></b-col>
                <b-col cols="6" style="text-align: right;"><h3>{{ customerData.bank_number }}</h3></b-col>
            </b-row>
            <b-row>
                <b-col cols="6"><h2>Atas nama</h2></b-col>
                <b-col cols="6" style="text-align: right;"><h3>{{ customerData.bank_account_name }}</h3></b-col>
            </b-row>
            <b-row style="background-color: #F8F8F8;">
                <b-col cols="6"><h2>Jumlah penarikan</h2></b-col>
                <b-col cols="6" style="text-align: right;"><h3>Rp. {{ amount }} </h3></b-col>
            </b-row>
            <br>
            <button :class="{ continueBtn: minAmount, grayBtn: !minAmount }" style="margin-bottom: 10px; width: 100%;" @click.prevent="cashout(amount)">lanjutkan</button> <br>
            <br>
        </form>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default{
    data(){
        return{
            amount: null,
            customerData: {},
            // flatAdminFee: 0,
            // adminFee: 0,
            // minFeeMaxAmount: 0,
            // feeAmountPercent : 0,
            // amountReceived: 0,
            minAmount: false,
        }
    },
    created(){
        this.customerData = JSON.parse(localStorage.earn)
        this.$store.dispatch("getFee", "eats");
        // setTimeout(() => {
        //     this.flatAdminFee = this.$store.state.flatAdminFee
        //     this.minFeeMaxAmount = this.$store.state.minFeeMaxAmount
        //     this.feeAmountPercent =  this.$store.state.feeAmountPercent
        // }, 500)
    },
    methods: {
        cashout(amount) {
            amount = Number(amount.replace(/[,.]/g,''))
            if(amount > this.balance){
                Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Jumlah penarikan tidak boleh melebihi saldo yang tersedia!',
                })
            }
            else if (!amount){
                Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Mohon masukkan jumlah uang yang ingin ditarik!',
                })
            }
            else if (amount < 100000){
                Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Penarikan minimal sebesar Rp. 100.000,00!',
                })
            } else{
                let data = {
                    amount:amount,
                    // amountReceived:this.amountReceived,
                    // adminFee:this.adminFee
                }
                this.$store.dispatch("storeFinalMutation", data)
            }
        },
        takeAll(){
            let money = `${this.balance}`
            this.amount = Intl.NumberFormat('id-ID').format(money.replace(/[,.]/g,''))
            this.formattedPrice
        },
        popUp(){
            Swal.fire({
                title: '<h1>Biaya Pencairan</h1>',
                text: 'Penarikan saldo dibawah Rp.1.000.000 (satu juta rupiah) akan dikenakan biaya admin sebesar Rp.20.000 (dua puluh ribu), sedangkan penarikan saldo diatas Rp.1.000.000 (satu juta rupiah) akan dikenakan biaya 2% dari jumlah penarikan saldo',
                })
        },
        returnDashboard(){
            this.$router.push('/Dashboard')
        }
    },
    computed: {
        balance: function () {
        let limit = this.$store.state.balance;
        if(!limit || limit == 0){
            limit = JSON.parse(localStorage.earn).balance.current_available_balance
            this.$store.dispatch("storeBalance", limit)
        }
        return limit;
        },
        formattedPrice() {
            let money = this.amount.replace(/[A-Za-z]/g,'').replaceAll('.','')
            if (money >= this.balance){
                money = this.balance
            }
            if (money >= 100000){
                this.minAmount = true
                // if (money < this.minFeeMaxAmount){
                //     this.adminFee = this.flatAdminFee
                //     this.amountReceived = money - this.adminFee
                // } else {
                //     this.adminFee = Math.round(money*this.feeAmountPercent/100)
                //     this.amountReceived = money - this.adminFee
                // }  
            } else {
                // this.adminFee = 0
                // this.amountReceived = 0
                this.minAmount = false
            }
            this.amount=this.amount.replace(/[A-Za-z]/g,'').replaceAll('.','')
            if(Number(this.amount) > this.balance){
                this.amount = `${this.balance}`
            } else{
                this.amount = `${this.amount}`
            }
            let rpstring = ""
            rpstring = Intl.NumberFormat('id-ID').format(this.amount.replace(/[,.]/g,''))
            this.amount = rpstring
        },
    },
}
</script>

<style scoped>
input[type="text"]
{
    font-size:15px;
}
input:focus{
    border: none;
    outline: none;
}
</style>

<style>

.swal2-actions{
    margin: 10px 0;
}

.swal2-styled{
    width: 90% !important;
}
</style>