<template>
    <div style="margin: 30px;">
        <div>
            <b-icon icon="chevron-left" aria-hidden="true" style="margin-right: 10px; width: 23px; height: 23px; cursor: pointer;" @click="returnDashboard()"></b-icon>
            <h2 style="display: inline-block; font-weight: 600;">Profil</h2>
        </div>
        <b-row>
            <b-col cols="4"><img src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/profileicon_k2QrQxfk9.png?updatedAt=1695115435342" style="max-width: 100%;"></b-col>
            <b-col cols="8">
                <h5 style="line-height: 7px; padding-top: 20px;"><strong>{{ profile.full_name }}</strong></h5>
                <p style="font-size: 15px; font-weight: 200; color: gray; line-height: 15px;"><strong>{{ profile.company.company_name }}</strong></p>
            </b-col>
        </b-row>
        <h5 style="line-height: 7px; padding-top: 20px;"><strong>Alamat</strong></h5>
        <p style="font-size: 15px; font-weight: 200; color: gray; line-height: 20px;"><strong>{{ profile.address }}</strong></p>
        <h5 style="line-height: 7px; padding-top: 20px;"><strong>Nomor HP</strong></h5>
        <p style="font-size: 15px; font-weight: 200; color: gray; line-height: 20px;"><strong>{{ profile.phone_number }}</strong></p>
        <h5 style="line-height: 7px; padding-top: 20px;"><strong>Jabatan Kerja</strong></h5>
        <p style="font-size: 15px; font-weight: 200; color: gray; line-height: 20px;"><strong>{{ profile.position }}</strong></p>
        <h5 style="line-height: 7px; padding-top: 20px;"><strong>Rekening Bank</strong></h5>
        <p style="font-size: 15px; font-weight: 200; color: gray; line-height: 20px;"><strong>{{ profile.bank.name }} - {{ profile.bank_number }} <br> a/n {{ profile.full_name }}</strong></p>
    </div>
</template>

<script>
import {
  BIcon,
  BIconChevronLeft,
  BIconPersonCircle,
} from "bootstrap-vue";

export default {
    data: {
        profile: {}
    },
    components: {
        BIcon,
        BIconChevronLeft,
        BIconPersonCircle
    },
    methods: {
        returnDashboard() {
            this.$router.push({ path: "/Dashboard" });
        },
    },
    created(){
        this.profile = JSON.parse(localStorage.earn)
    }
}
</script>

<style>

</style>