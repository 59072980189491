<!-- eslint-disable -->
<template>
  <div style="margin: 10px 20px 10px 20px">
    <img
      src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
      style="max-width: 7%"
      @click="returnWithdraw()"
    />
    <h1>Konfirmasi Penarikan</h1>
    <br />
    <div style="text-align: center">
      <h4>Jumlah</h4>
      <h5 style="font-size: 25px; font-weight: 900">
        Rp.
        {{
          withdrawBalance
            .toString()
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
        }}
      </h5>
    </div>
    <div style="border-radius: 10px; background-color: #f8f8f8">
      <b-row style="padding: 5px; margin-left: 5px">
        <b-col cols="8"
          ><h2>
            Biaya admin<img
              src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon%20information_A83UMsWtU.png?updatedAt=1702285482681"
              @click="popUp()"
              style="width: 10%; margin-left: 10px"
            /></h2
        ></b-col>
        <b-col cols="4"
          ><h3>
            Rp.
            {{
              adminFee
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </h3></b-col
        >
      </b-row>
      <b-row style="padding: 5px; margin-left: 5px">
        <b-col cols="8"><h2>Jumlah diterima</h2></b-col>
        <b-col cols="4"
          ><h3>
            Rp.
            {{
              amountReceived
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </h3></b-col
        >
      </b-row>
      <hr />
      <b-row style="padding: 5px; margin-left: 5px">
        <b-col cols="8"><h2>Total Pembayaran</h2></b-col>
        <b-col cols="4" style="color: #3d3d3d"
          ><h3>
            Rp.
            {{
              withdrawBalance
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </h3></b-col
        >
      </b-row>
    </div>
    <br />
    <br />
    <h4>Masukkan pin</h4>
    <form style="text-align: center">
      <span v-for="(pin, index) in pins" :key="pin.id">
        <input
          type="password"
          inputmode="numeric"
          v-model="pin.datatype"
          ref="pins"
          maxlength="1"
          @input="moveToNextField($event.target.value, index)"
          @keyup.delete="back($event.target.value, index)"
          style="
            padding: 10px;
            margin-left: 2%;
            margin-right: 2%;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            width: 10%;
            text-align: center;
          "
        />
      </span>
    </form>
    <br />
    <button
      @click="withdraw()"
      :class="{ continueBtn: fieldFull, grayBtn: !fieldFull }"
      v-if="!busy"
      style="width: 100%"
    >
      Lanjutkan Penarikan
    </button>
    <button
      v-if="busy"
      class="grayBtn"
      style="margin-bottom: 10px; width: 100%"
    >
      <b-spinner large style="text-align: center"></b-spinner>
    </button>
    <br />
    <br />
    <h3>
      Dengan melanjutkan, saya mengerti dan menyetujui
      <router-link to="/TnC" style="text-decoration: none; color: #5f7af7"
        >Syarat & Ketentuan</router-link
      >
      Akses Gaji Awal Atoer.
    </h3>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      pins: [
        { id: "1", datatype: "" },
        { id: "2", datatype: "" },
        { id: "3", datatype: "" },
        { id: "4", datatype: "" },
        { id: "5", datatype: "" },
        { id: "6", datatype: "" },
      ],
      fieldFull: false,
      busy: false,
    };
  },
  methods: {
    back(value, index) {
      if (value === "" && index > 0) {
        if (this.pins[index].datatype == null) {
          this.$refs.pins[index - 1].focus();
          this.pins[index - 1].datatype = null;
        } else {
          this.pins[index].datatype = null;
        }
      }
    },
    moveToNextField(value, index) {
      for (let i = 0; i < this.pins.length; i++) {
        if (!this.pins[i].datatype) {
          this.fieldFull = false;
          break;
        } else {
          this.fieldFull = true;
        }
      }
      const nextIndex = index + 1;
      if (nextIndex < this.pins.length && value.length > 0) {
        this.$refs.pins[nextIndex].focus();
      }
    },
    returnWithdraw() {
      this.$router.push({ path: "CustomWithdraw" });
    },
    popUp() {
      Swal.fire({
        title: "<h1>Biaya Pencairan</h1>",
        text: "Penarikan saldo dibawah Rp.1.000.000 (satu juta rupiah) akan dikenakan biaya admin sebesar Rp.20.000 (dua puluh ribu), sedangkan penarikan saldo diatas Rp.1.000.000 (satu juta rupiah) akan dikenakan biaya 2% dari jumlah penarikan saldo",
      });
    },
    withdraw() {
      let full = false;
      let pinData = "";
      for (let i = 0; i < this.pins.length; i++) {
        if (!this.pins[i].datatype) {
          full = false;
          break;
        } else {
          pinData += this.pins[i].datatype;
          full = true;
        }
      }
      if (full) {
        this.busy = true;
        this.$store.dispatch("balanceMutation", pinData);
        for (let i = 0; i < this.pins.length; i++) {
          this.pins[i].datatype = "";
        }
      }
      this.fieldFull = false;
      setTimeout(() => {
        do {
          if (this.$store.state.clearBusy) {
            this.busy = false;
          } else {
            this.busy = true;
          }
        } while (this.busy == true);
      }, 4000);
    },
  },
  computed: {
    withdrawBalance: function () {
      let amount = this.$store.state.mutation;
      if (amount) {
        localStorage.setItem("withdrawAmount", amount);
        return amount;
      } else {
        amount = JSON.parse(localStorage.withdrawAmount);
        return amount;
      }
    },
    adminFee: function () {
      let amount = this.$store.state.mutation;
      if (amount) {
        let minFee = this.$store.state.minFeeMaxAmount;
        let flatAdminFee = this.$store.state.flatAdminFee;
        let feeAmountPercent = this.$store.state.feeAmountPercent;
        if (amount < minFee) {
          amount = flatAdminFee;
        } else {
          amount = Math.round((amount * feeAmountPercent) / 100);
        }
        localStorage.setItem("adminFee", amount);
        return amount;
      } else {
        amount = JSON.parse(localStorage.adminFee);
        return amount;
      }
    },
    amountReceived: function () {
      let amount = this.$store.state.mutation;
      if (amount) {
        let minFee = this.$store.state.minFeeMaxAmount;
        let flatAdminFee = this.$store.state.flatAdminFee;
        let feeAmountPercent = this.$store.state.feeAmountPercent;
        if (amount < minFee) {
          amount = amount - flatAdminFee;
        } else {
          amount = amount - Math.round((amount * feeAmountPercent) / 100);
        }
        localStorage.setItem("amountReceived", amount);
        return amount;
      } else {
        amount = JSON.parse(localStorage.amountReceived);
        return amount;
      }
    },
  },
  created() {
    if (!this.$store.state.mutation || this.$store.state.mutation == 0) {
      let dataWithdraw = localStorage.earnWithdraw;
      this.$store.dispatch("storeFinalMutation", dataWithdraw);
    }
    if (this.$store.state.adminFee == 0 || !this.$store.state.adminFee) {
      this.$store.dispatch("getFee", "eats");
    }
    this.profile = JSON.parse(localStorage.earn);
    this.busy = false;
  },
};
</script>

<style>
input:focus {
  outline: none;
}
</style>
