<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div style="text-align: center">
    <img
      src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/atoerlogo%20Animated_brH-NkHFY.gif?updatedAt=170192950426"
      style="
        max-width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 45%;
      "
    />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  created() {
    if (
      !localStorage.AccessKey ||
      this.$route.query.key !== localStorage.AccessKey
    ) {
      let key = this.$route.query.key;
      localStorage.setItem("AccessKey", key);
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/auth/${key}/access`,
        method: "get",
      })
        .then((res) => {
          localStorage.setItem("earnAccessToken", res.data.data.access);
          localStorage.setItem("earnCustUUID", res.data.data.customer_id);
          axios({
            url: `${process.env.VUE_APP_API_URL}/api/v1/customer/${localStorage.earnCustUUID}/account`,
            method: "get",
            headers: {
              Authorization: "Bearer " + localStorage.earnAccessToken,
            },
          })
            .then((res) => {
              this.$store.dispatch(
                "storeBalance",
                res.data.data.balance.balance_left_amount
              );
              localStorage.setItem("earn", JSON.stringify(res.data.data));
            })
            .catch((err) => {
              this.$router.push("/NotFound");
            });
        })
        .catch((err) => {
          this.$router.push("/NotFound");
        });
    }
    //axios({
    //   url: `${process.env.VUE_APP_API_URL}/api/v1/ppob/get_ppob`,
    //   method: "get",
    //   headers: {
    //     Authorization: "Bearer " + localStorage.earnAccessToken,
    //   },
    // })
    //   .then((res) => {
    //     let PPOBStatus = res.data.data;
    //     localStorage.setItem("PPOBStatus", JSON.stringify(PPOBStatus));
    //   })
    //   .catch((err) => {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: err.response.data.message,
    //     });
    //   });

    setTimeout(() => {
      if (localStorage.earnCustUUID) {
        this.$router.push("/Dashboard");
      }
    }, 5000);
  },
};
</script>

<style></style>
