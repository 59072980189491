<template>
    <div style="margin: 30px;">
        <div>
          <img src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095" style="max-width: 7%;" @click="returnDashboard()">
          <h1>SYARAT DAN KETENTUAN APLIKASI ATOER - PRODUK EWA</h1>
          <h1>("Ketentuan Penggunaan")</h1>
        </div>
        <br>
        <p>Selamat datang di Aplikasi Atoer yang dimiliki dan dikelola oleh AGD (sebagaimana didefinisikan di bawah). Ketentuan Penggunaan ini mengatur tentang syarat dan ketentuan atas Produk EWA dalam Aplikasi Atoer. Dengan mengakses dan menggunakan Produk EWA, Anda mengakui bahwa Anda telah membaca, memahami, menerima, dan menyetujui Ketentuan Penggunaan ini dan oleh karenanya Anda telah setuju untuk terikat secara hukum oleh Ketentuan Penggunaan serta dokumen-dokumen lain yang terkait sehubungan dengan Ketentuan Penggunaan ini. Apabila Anda tidak menyetujui Ketentuan Penggunaan ini, mohon untuk tidak menggunakan Produk EWA dalam Aplikasi Atoer ini.</p>
        <br>
        <p>
          Aplikasi Atoer dan Produk EWA dimaksudkan untuk digunakan oleh (i) Anda merupakan Karyawan yang terdaftar dalam Pengguna; (ii) Pengguna telah memiliki kapasitas hukum dan menjadi pihak yang terikat dalam suatu perjanjian definitif dengan AGD; (ill) Anda bekerja kepada Pengguna dan sepakat untuk menggunakan Aplikasi Atoer, serta Produk EWA: (iv) tidak dapat digunakan oleh orang yang tidak berhak dan penggunaannya berpotensi, dianggap atau sudah melanggar hukum, dan secara umum memenuhi persyaratan yang ditentukan oleh diskresi tunggal dari AGD untuk menggunakan Aplikasi Atoer. Apabila Anda telah memenuhi kualifikasi tersebut, maka Anda diperkenankan untuk mengakses atau menggunakan Produk EWA.
        </p>
        <br>
        <p>
          <strong>DEFINISI</strong>
        </p>
        <p>
          Istilah-istilah yang digunakan dalam Ketentuan Penggunaan ini mempunyai arti sebagai berikut:
        </p>
        <ol class="roman">
          <ul>
            <li><strong>"Anda"</strong> atau <strong>"Karyawan"</strong> adalah karyawan, pekerja, buruh atau mitra dari Pengguna yang diikat berdasarkan perjanjian kerja atau kemitraan yang sah.</li>
            <li><strong>“Aplikasi Atoer"</strong>adalah Aplikasi Atoer digital, perangkat lunak, atau layanan teknologi yang diciptakan, dikembangkan, dikelola, dan dimiliki oleh AGD yang memiliki layanan manajemen finansial dan manfaat tambahan, berupa Produk EWA, bagi Pengguna dan Karyawan sebagaimana mungkin diubah dan dimodifikasi dari waktu ke waktu.</li>
            <li><strong>"Hari Kerja"</strong> adalah hari selain Sabtu, Minggu, dan hari libur resmi nasional, yang mana bank buka melakukan kegiatan usahanya dan menyelenggarakan kegiatan kliring sesuai dengan ketentuan Bank Indonesia.</li>
            <li><strong>"Jumlah Terutang"</strong> adalah jumlah utang atas EWA Terpakai yang jatuh tempo dan harus dibayar namun belum dibayar oleh Karyawan berdasarkan Ketentuan Penggunaan ini.</li>
            <li><strong>"Kami"</strong> atau <strong>"AGD"</strong> adalah PT AMBIL GAJI DULUAN.</li>
            <li><strong>"Kebijakan Privasi"</strong> adalah perjanjian antara AGD dan Anda mengenai Kebijakan Privasi sehubungan dengan penggunaan Aplikasi Atoer dan Produk EWA.</li>
            <li><strong>"Gaji"</strong> adalah upah Karyawan yang diterima dari Pengguna sesuai dengan perjanjian kerja mereka, serta Undang-Undang Republik Indonesia No. 13 tahun 2003 tentang Ketenagakerjaan (sebagaimana diubah terakhir dengan Peraturan Pemerintah Pengganti Undang-Undang Republik Indonesia No. 2 tahun 2022 tentang Cipta Kerja) dan peraturan pelaksanaanya, sebagaimana mungkin diubah dari waktu ke waktu.</li>
            <li><strong>"Konfirmasi Pencairan EWA"</strong> adalah pernyataan konfirmasi pencairan Produk EWA sesuai dengan transaksi yang hendak dilakukan oleh Karyawan dalam Aplikasi Atoer.</li>
            <li><strong>"Atoer"</strong> adalah berarti pihak-pihak terafiliasi dengan AGD yang secara langsung maupun tidak langsung mengontrol, berada dalam kontrol bersama dengan, atau dikontrol oleh AGD untuk menunjang AGD dalam melaksanakan Ketentuan Penggunaan ini.</li>
            <li><strong>"Pengguna"</strong> adalah badan usaha atau badan hukum yang didirikan secara sah berdasarkan hukum negara Republik Indonesia yang mendaftarkan karyawannya, yaitu Karyawan, untuk dapat mengakses dan menggunakan Aplikasi Atoer.</li>
            <li><strong>"Produk EWA"</strong>, yaitu produk Earned Wage Access, adalah produk atau fitur dalam Aplikasi Atoer yang menawarkan kemudahan bagi para karyawan dalam mengakses gajinya lebih awal berupa limit atau saldo dan diperbaharui setiap bulannya yang merupakan bagian dari Gaji yang berhak diterima oleh Karyawan.</li>
            <li><strong>"EWA Terpakai"</strong> adalah jumlah dari Produk EWA yang telah dicairkan atau digunakan untuk bertransaksi dalam Aplikasi Atoer oleh AGD kepada Karyawan.</li>
            <li><strong>"Tanggal Pembayaran"</strong> adalah tanggal pembayaran kembali EWA Terpakai pada setiap bulan, yang akan jatuh pada tanggal penerimaan Gaji bagi Karyawan yang ditetapkan oleh Pengguna.</li>
          </ul>
        </ol>
        <p><strong>KETENTUAN PENGGUNAAN PRODUK EWA</strong></p>
        <ol class="roman">
          <ul>
            <li>Karyawan wajib untuk mengikuti proses pendaftaran serta langkah-langkah aktivasi lainnya sebelum dapat mengakses dan menggunakan Aplikasi Atoer, termasuk persetujuan dari Karyawan untuk tunduk dan terikat pada Ketentuan Penggunaan ini. Karyawan yang telah berhasil mendaftar dan mengaktivasi Aplikasi Atoer akan memperoleh limit atau saldo atas Produk EWA sesuai dengan kesepakatan dan kerja sama antara AGD dan Pengguna.</li>
            <li>Karyawan yang sudah berhak mengakses dan menggunakan Aplikasi Atoer dapat melakukan permintaan dan penggunaan Produk EWA. Atas permintaan dan penggunaan Produk EWA oleh Karyawan tersebut, maka AGD akan menerbitkan Konfirmasi Pencairan EWA.</li>
            <li>AGD akan memfasilitasi transaksi atas Produk EWA yang dilakukan oleh Karyawan berdasarkan dan sesuai dengan Konfirmasi Pencairan EWA.</li>
            <li>Karyawan, melalui Pengguna, wajib melakukan pembayaran kembali atas EWA Terpakai kepada AGD pada setiap Tanggal Pembayaran dengan jumlah pembayaran sesuai dengan Jumlah Terutang. Karyawan mengakui dan setuju bahwa dalam hal Karyawan menggunakan Produk EWA ini, maka Gaji dan semua manfaat lainnya yang kemudian akan dibayarkan kepadanya akan terlebih dahulu diperuntukkan untuk pembayaran kembali atas EWA Terpakai.</li>
            <li>Pembayaran atas EWA Terpakai dilakukan dengan cara Pengguna akan memotong langsung Gaji yang akan dibayarkan oleh Pengguna kepada Karyawan, maka Gaji yang berhak diterima oleh Karyawan pada tanggal cut off yang tertera akan berkurang sesuai dengan EWA Terpakai. Oleh karena itu, Karyawan dengan ini memberikan kuasa yang tidak dapat ditarik kembali kepada Pengguna serta AGD untuk melakukan pemotongan atas Gaji tersebut.</li>
            <li>Apabila hubungan ketenagakerjaan antara Karyawan dan Pengguna berakhir atau diakhiri karena alasan apapun sehingga mempengaruhi besaran Gaji yang akan dibayarkan kepada Karyawan dan tidak mencukupi untuk melunasi EWA Terpakai, maka Karyawan berkewajiban dan berkomitmen untuk melakukan pelunasan EWA Terpakai secara langsung kepada AGD sesuai dengan prosedur yang ditetapkan oleh AGD. Karyawan dengan ini mengakui bahwa tidak dipenuhinya ketentuan ini merupakan suatu pelanggaran hukum dimana Karyawan tidak memenuhi kewajibannya sebagaimana tertuang dalam Konfirmasi Pencairan EWA (wanprestasi).</li>
            <li>Dengan segera setelah diminta, Karyawan harus memberikan kepada Pengguna atau AGD mengenai segala data, informasi dan/atau dokumen yang berkaitan dengan perubahan identitas diri, kondisi keuangan, kondisi pekerjaan, dan hal-hal terkait lainnya yang dianggap perlu oleh AGD terkait dengan penggunaan Produk EWA dan/atau pembayaran kembali yang timbul karenanya.</li>
            <li>Karyawan dengan ini menyatakan dan menjamin kepada AGD bahwa:
              <ol class="roman">
                <ul>
                  <li>ia adalah warga negara Indonesia yang cakap untuk mengambil tindakan-tindakan hukum, termasuk untuk memiliki akun dalam Aplikasi Atoer, menggunakan Aplikasi Atoer dan Produk EWA, dan/atau mengikatkan diri pada Ketentuan Penggunaan ini dan Kebijakan Privasi;</li>
                  <li>ia telah membaca dan memahami Ketentuan Penggunaan ini dan Kebijakan Privasi merupakan suatu kewajiban hukum yang sah dan mengikatnya;</li>
                  <li>ia bertindak untuk dan atas kepentingan diri sendiri, bukan untuk dan atas kepentingan pihak lain manapun;</li>
                  <li>tujuan penggunaan dana EWA oleh Karyawan berdasarkan Ketentuan Penggunaan ini adalah sesuai dengan ketentuan hukum yang berlaku di Indonesia serta tidak akan digunakan untuk keperluan kecurangan, pencucian uang, pendanaan terorisme dan/atau perbuatan pidana lainnya;</li>
                  <li>ia memiliki kekuasaan dan kewenangan untuk menandatangani, melaksanakan dan menyampaikan, dan telah melakukan semua tindakan yang diperlukan untuk mendapatkan kewenangan atas penandatanganan, pelaksanaan dan penyampaian Ketentuan Penggunaan ini dan transaksi didalamnya, termasuk untuk memperoleh persetujuan pasangan (sebagaimana berlaku):</li>
                  <li>persetujuan yang diberikan dalam Ketentuan Penggunaan ini adalah benar persetujuan dari ia sendiri secara elektronik dan oleh karenanya sah dan berkekuatan hukum selayaknya suatu tanda tangan basah atau persetujuan yang sah;</li>
                  <li>ia tidak akan membatalkan Ketentuan Penggunaan ini dengan alasan perjanjian tidak sah karena menggunakan persetujuan elektronik dan/atau mempermasalahkan keabsahan persetujuan elektronik dalam Ketentuan Penggunaan ini.</li>
                </ul>
              </ol>
            </li>
            <li>Dengan tunduk pada Kebijakan Privasi dari Atoer, Karyawan dengan ini memberikan otorisasi kepada AGD untuk menampilkan atau mengungkapkan data, informasi, dan/atau dokumen terkait profil dan identitas Karyawan, baik kepada AGD maupun pihak lain yang dianggap perlu oleh AGD terbatas untuk tujuan dari Ketentuan Penggunaan ini.</li>
            <li>AGD berdasarkan pertimbangannya sendiri dapat melakukan penghentian Aplikasi Atoer, termasuk Produk EWA tanpa pemberitahuan terlebih dahulu kepada Karyawan. Penghentian ini tidak semata-mata menghilangkan kewajiban Karyawan untuk tetap melakukan pelunasan berdasarkan Ketentuan Penggunaan ini, yang telah timbul sebelum tanggal efektif penghentian tersebut.</li>
            <li>Setiap komunikasi yang akan dilakukan berdasarkan atau berkaitan dengan Ketentuan Penggunaan ini harus dilakukan secara tertulis dan, kecuali dinyatakan lain, dapat dilakukan secara langsung, melalui pos, telepon, pesan, email, Aplikasi Atoer, atau bentuk komunikasi lainnya yang disetujui oleh AGD.</li>
          </ul>
        </ol>
        <br>
        <p><strong>KETENTUAN BIAYA, PENAGIHAN DAN GANTI RUGI</strong></p>
        <br>
        <ol class="roman">
          <ul>
            <li>Apabila Karyawan gagal membayar Jumlah Terutang pada tanggal jatuh tempo, maka Jumlah Terutang tersebut akan dikenakan denda sebesar 0,1% (nol koma satu persen) dari Jumlah Terutang setiap harinya sejak tanggal jatuh tempo sampai dengan tanggal aktual pembayaran (sebagaimana relevan).</li>
            <li>Karyawan harus, dalam waktu 3 (tiga) Hari Kerja sejak ditagih, memberikan ganti rugi kepada AGD atas biaya-biaya, kerugian dan tanggung jawab yang timbul sebagai akibat dari:
              <ol>
                <ul>
                  <li>Timbulnya suatu peristiwa cidera janji sebagaimana dimaksud dalam Ketentuan Penggunaan ini;</li>
                  <li>Kegagalan Karyawan untuk membayar Jumlah Terutang pada jumlah yang benar dan pada saat jatuh tempo; dan/atau</li>
                  <li>Suatu EWA Terpakai (atau bagian daripadanya) tidak dibayar lebih dulu sesai dengan ketentuan dalam pemberitahuan pembayaran lebih dulu yang diberkan oleh Karyawan.</li>
                </ul>
              </ol>
            </li>
            <li>Karyawan wajib memberi penggantian kepada AGD atas seluruh pengeluaran yang wajar (termasuk biaya jasa hukum) yang ditanggung oleh AGD sehubungan dengan pelaksanaan atas Ketentuan Penggunaan ini.</li>
            <li>Apabila Karyawan gagal melakukan suatu pembayaran dalam waktu yang ditentukan, maka Karyawan dengan ini memberikan otorisasi kepada AGD untuk melakukan segala tindakan yang diperlukan untuk mengambil pelunasan atas Jumlah Terutang, termasuk namun tidak terbatas pada:
              <br>
              <br>
              (a) menghubungi Karyawan dan/atau pihak yang tercantum dalam kontak darurat, termasuk melalui media sosial atau secara daring (online): <br> (b) melakukan penagihan terhadap Karyawan; <br> (c) menggunakan jasa pihak ketiga untuk meminta pelunasan atas Jumlah Terutang terhadap Karyawan; atau <br> (d) melakukan upaya hukum yang diperbolehkan menurut peraturan perundang-undangan yang berlaku.
            </li>
          </ul>
        </ol>
        <p><strong>KETENTUAN LAIN-LAIN</strong></p>
        <ol>
          <ul>
            <li>Setiap hal yang berkaitan dengan hak dan kewajiban AGD, Pengguna dan Karyawan, akses ke, dan/atau penggunaan dari, Aplikasi Atoer atau Produk EWA diatur dan dibuat berdasarkan hukum Indonesia. Kegagalan untuk memenuhi syarat-syarat tersebut merupakan penggunaan yang tidak sah dan ilegal dari dan/atau akses ke Aplikasi Atoer atau Produk EWA.</li>
            <li>Setiap peristiwa atau keadaan yang ditetapkan dalam ketentuan ini adalah suatu peristiwa cidera janji sebagai berikut:
              <ol>
                <ul>
                  <li>Karyawan tidak melakukan pembayaran atas kewajibannya yang telah jatuh tempo berdasarkan Konfirmasi Pencairan EWA atau tidak mematuhi kewajiban lain yang ditetapkan berdasarkan Ketentuan Penggunaan ini;</li>
                  <li>
                    Pernyataan atau keterangan yang dibuat atau dianggap diulang oleh Karyawan dalam Ketentuan Penggunaan ini yang disampaikan berdasarkan atau berkaitan dengan Ketentuan Penggunaan ini adalah atau terbukti tidak benar atau menyesatkan dalam segala hal yang material pada saat dibuat atau dianggap diulang;
                  </li>
                  <li>
                    Pelaksanaan kewajiban Karyawan berdasarkan Ketentuan Penggunaan ini tidak sah, dianggap tidak sah atau menjadi tidak sah; dan/atau
                  </li>
                  <li>
                    Karyawan tidak mengakui keberadaan atau keabsahan Ketentuan Penggunaan ini atau menunjukkan maksud untuk tidak mengakui keberadaan atau keabsahan Ketentuan Penggunaan ini.
                  </li>
                </ul>
              </ol>
            </li>
            <li>Setiap gugatan, klaim, atau perselisihan yang timbul dari atau sehubungan dengan Ketentuan Penggunaan ini termasuk, tetapi tidak terbatas pada, suatu perselisihan mengenai penandatanganan, keberadaan, keberlakuan, keterlaksanaan, pelanggaran, pelaksanaan. penafsiran, implementasi, pengakhiran atau konsekuensi dari pengakhiran dapat dirujuk kepada dan diselesaikan melalui Pengadilan Negeri Jakarta Pusat.</li>
            <li>Ketentuan Penggunaan ini dapat diubah, dimodifikasi, ditambah atau diperbaharui dari waktu ke waktu tanpa pemberitahuan terlebih dahulu. Kami menyarankan agar Anda membaca secara seksama dan memeriksa halaman Ketentuan Penggunaan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan Aplikasi Atoer serta Produk EWA, maka Anda dianggap menyetujui perubahan perubahan dalam Ketentuan Penggunaan ini. </li>
            <li>Dalam hal terdapat perbedaan apapun di antara Ketentuan Penggunaan ini dan media pemasaran manapun (termasuk namun tidak terbatas pada media daring). Ketentuan Penggunaan ini yang akan berlaku.</li>
            <li>Ketentuan lainnya yang tidak secara tegas diatur dalam Ketentuan Penggunaan ini, akan mengacu pada ketentuan yang diatur pada syarat & ketentuan lainnya yang diatur dalam Aplikasi Atoer.</li>
            <li>Anda dengan ini melepaskan Kami dari segala bentuk tanggung jawab dan wajib mengganti seluruh kerugian yang Kami alami atas penyalahgunaan Aplikasi Atoer, termasuk Produk EWA dalam hal terdapat pelanggaran terhadap Ketentuan Penggunaan, dan syarat & ketentuan lainnya yang diatur dalam Aplikasi Atoer.</li>
          </ul>
        </ol>
    </div>
</template>

<script>
export default{
    methods: {
        returnDashboard() {
            this.$router.push({ path: "/Dashboard" });
        },
    }
}
</script>

<style>
ol{
  padding: 13px !important;
}
.roman{
  list-style-type: upper-roman;
}
</style>
