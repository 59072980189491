<!-- eslint-disable -->
<template>
  <div style="margin: 10px 20px 10px 20px">
    <img
      src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
      style="max-width: 7%"
      @click="returnClaimVoucher()"
    />
    <h1>Konfirmasi Claim Voucher</h1>
    <br />
    <div style="border-radius: 10px; background-color: #f8f8f8">
      <b-row style="padding: 5px; margin-left: 5px">
        <b-col cols="8"><h2>Kode Voucher</h2></b-col>
        <b-col cols="4"
          ><h3>
            {{ voucherCode }}
          </h3></b-col
        >
      </b-row>
      <b-row style="padding: 5px; margin-left: 5px">
        <b-col cols="8"><h2>Nilai Voucher</h2></b-col>
        <b-col cols="4"
          ><h3>
            Rp.
            {{
              voucherAmount
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </h3></b-col
        >
      </b-row>
      <hr />
    </div>
    <br />
    <br />
    <h4>Masukkan pin</h4>
    <form style="text-align: center">
      <span v-for="(pin, index) in pins" :key="pin.id">
        <input
          type="password"
          inputmode="numeric"
          v-model="pin.datatype"
          ref="pins"
          maxlength="1"
          @input="moveToNextField($event.target.value, index)"
          @keyup.delete="back($event.target.value, index)"
          style="
            padding: 10px;
            margin-left: 2%;
            margin-right: 2%;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            width: 10%;
            text-align: center;
          "
        />
      </span>
    </form>
    <br />
    <button
      @click="claim()"
      :class="{ continueBtn: fieldFull, grayBtn: !fieldFull }"
      v-if="!busy"
      style="width: 100%"
    >
      Lanjutkan Penarikan
    </button>
    <button
      v-if="busy"
      class="grayBtn"
      style="margin-bottom: 10px; width: 100%"
    >
      <b-spinner large style="text-align: center"></b-spinner>
    </button>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      pins: [
        { id: "1", datatype: "" },
        { id: "2", datatype: "" },
        { id: "3", datatype: "" },
        { id: "4", datatype: "" },
        { id: "5", datatype: "" },
        { id: "6", datatype: "" },
      ],
      fieldFull: false,
      busy: false,
      voucherCode: this.$store.state.voucher,
      voucherAmount: this.$store.state.nominal,
      codeUUID: this.$store.state.codeUUID,
      profile: {},
    };
  },
  methods: {
    back(value, index) {
      if (value === "" && index > 0) {
        if (this.pins[index].datatype == null) {
          this.$refs.pins[index - 1].focus();
          this.pins[index - 1].datatype = null;
        } else {
          this.pins[index].datatype = null;
        }
      }
    },
    moveToNextField(value, index) {
      for (let i = 0; i < this.pins.length; i++) {
        if (!this.pins[i].datatype) {
          this.fieldFull = false;
          break;
        } else {
          this.fieldFull = true;
        }
      }
      const nextIndex = index + 1;
      if (nextIndex < this.pins.length && value.length > 0) {
        this.$refs.pins[nextIndex].focus();
      }
    },
    returnClaimVoucher() {
      this.$router.push({ path: "InputVoucher" });
    },
    claim() {
      let full = false;
      let pinData = "";
      for (let i = 0; i < this.pins.length; i++) {
        if (!this.pins[i].datatype) {
          full = false;
          break;
        } else {
          pinData += this.pins[i].datatype;
          full = true;
        }
      }
      if (full) {
        this.busy = true;
        console.log(this.profile.id);
        let data = {
          redeem_uuid: this.codeUUID,
          customer_uuid: this.profile.id,
          entered_pin: pinData,
        };

        this.$store.dispatch("storeVoucherCode", data);
        for (let i = 0; i < this.pins.length; i++) {
          this.pins[i].datatype = "";
        }
      }
      this.fieldFull = false;
      setTimeout(() => {
        do {
          if (this.$store.state.clearBusy) {
            this.busy = false;
          } else {
            this.busy = true;
          }
        } while (this.busy == true);
      }, 4000);
    },
  },
  created() {
    if (!this.$store.state.voucher || this.$store.state.voucher == 0) {
      let dataWithdraw = JSON.parse(localStorage.voucher);
      this.$store.dispatch("storeClaimVoucher", dataWithdraw);
      this.voucherCode = dataWithdraw.code;
      this.voucherAmount = dataWithdraw.nominal;
      this.codeUUID = dataWithdraw.codeUUID;
      console.log(dataWithdraw);

      console.log(this.codeUUID);
    }
    this.profile = JSON.parse(localStorage.earn);
    this.busy = false;
  },
};
</script>

<style>
input:focus {
  outline: none;
}
</style>
