<!-- eslint-disable -->
<template>
  <div style="margin: 10px 20px 10px 20px">
    <img
      src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
      style="max-width: 7%"
      @click="returnPLNBilling()"
    />
    <h1>Checkout</h1>
    <div>
      <img
        src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-pln_F7nZkNenO.png?updatedAt=1721748123057"
        alt=""
        style="max-width: 10%"
      />
      <h2 style="display: inline-block">Listrik PLN</h2>
    </div>
    <br />
    <b-row>
      <b-col cols="6"><h2>Produk</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ productName }}</h3></b-col
      >
    </b-row>
    <b-row style="background-color: #f8f8f8">
      <b-col cols="6"><h2>No Meter</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ meterId }}</h3></b-col
      >
    </b-row>
    <b-row>
      <b-col cols="6"><h2>Nama</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ name }}</h3></b-col
      >
    </b-row>
    <b-row style="background-color: #f8f8f8">
      <b-col cols="6"><h2>Tarif / Daya</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ powerType }}</h3></b-col
      >
    </b-row>
    <b-row>
      <b-col cols="6"><h2>Biaya Admin</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>Rp. {{ adminFee }}</h3></b-col
      >
    </b-row>
    <br />
    <hr style="margin: 0 0 10px 0" />
    <b-row>
      <b-col cols="6"><h2>Total Harga</h2></b-col>
      <b-col cols="6" style="text-align: right"
        ><h3>{{ totalFee }}</h3></b-col
      >
    </b-row>
    <br />
    <h4>Masukkan PIN</h4>
    <form style="text-align: center">
      <span v-for="(pin, index) in pins" :key="pin.id">
        <input
          type="password"
          inputmode="numeric"
          v-model="pin.datatype"
          ref="pins"
          maxlength="1"
          @input="moveToNextField($event.target.value, index)"
          @keyup.delete="back($event.target.value, index)"
          style="
            padding: 10px;
            margin-left: 2%;
            margin-right: 2%;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            width: 10%;
            text-align: center;
          "
        />
      </span>
    </form>
    <br />
    <button
      @click="checkoutPLN()"
      :class="{ continueBtn: fieldFull, grayBtn: !fieldFull }"
      v-if="!busy"
      style="width: 100%"
    >
      Lanjutkan Pembelian
    </button>
    <button
      v-if="busy"
      class="grayBtn"
      style="margin-bottom: 10px; width: 100%"
    >
      <b-spinner large style="text-align: center"></b-spinner>
    </button>
    <br />
    <br />
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      pins: [
        { id: "1", datatype: "" },
        { id: "2", datatype: "" },
        { id: "3", datatype: "" },
        { id: "4", datatype: "" },
        { id: "5", datatype: "" },
        { id: "6", datatype: "" },
      ],
      fieldFull: false,
      busy: false,
      productName: "",
      meterId: "",
      name: "",
      powerType: "RM1 / 1300VA",
      adminFee: "3000",
      totalFee: "",
    };
  },
  methods: {
    back(value, index) {
      if (value === "" && index > 0) {
        if (this.pins[index].datatype == null) {
          this.$refs.pins[index - 1].focus();
          this.pins[index - 1].datatype = null;
        } else {
          this.pins[index].datatype = null;
        }
      }
    },
    moveToNextField(value, index) {
      for (let i = 0; i < this.pins.length; i++) {
        if (!this.pins[i].datatype) {
          this.fieldFull = false;
          break;
        } else {
          this.fieldFull = true;
        }
      }
      const nextIndex = index + 1;
      if (nextIndex < this.pins.length && value.length > 0) {
        this.$refs.pins[nextIndex].focus();
      }
    },
    returnPLNBilling() {
      this.$router.push({ path: "PLN" });
    },
    checkoutPLN() {
      let full = false;
      let pinData = "";
      for (let i = 0; i < this.pins.length; i++) {
        if (!this.pins[i].datatype) {
          full = false;
          break;
        } else {
          pinData += this.pins[i].datatype;
          full = true;
        }
      }
      if (full) {
        // this.busy = true;
        // this.$store.dispatch("balanceMutationPLN", pinData);
        if (pinData == "123456") {
          let data = {
            meterId: this.meterId,
            totalFee: this.totalFee,
            date: Date.now(),
          };
          Swal.fire({
            imageUrl:
              "https://ik.imagekit.io/44d0h1g5ht2/Atoer/requestsenticon_PI_8fIRig.png?updatedAt=1701929551963",
            imageWidth: 50,
            imageHeight: 50,
            text: "Permintaan anda telah diterima dan diproses",
          }).then(() => {
            this.$store.dispatch("storeInvoicePLN", data);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "PIN yang anda masukkan salah!",
          });
        }
        for (let i = 0; i < this.pins.length; i++) {
          this.pins[i].datatype = "";
        }
      }
      //   this.fieldFull = false;
      //   setTimeout(() => {
      //     do {
      //       if (this.$store.state.clearBusy) {
      //         this.busy = false;
      //       } else {
      //         this.busy = true;
      //       }
      //     } while (this.busy == true);
      //   }, 4000);
    },
  },
  computed: {},
  created() {
    let data;
    let customerData = JSON.parse(localStorage.earn);
    if (Object.keys(this.$store.state.mutationPLN).length == 0) {
      data = JSON.parse(localStorage.earnMutationPLN);
      this.$store.dispatch("storeMutationPLN", data);
    } else if (this.$store.state.mutationPLN) {
      data = this.$store.state.mutationPLN;
    }
    let rpstring = data.amount.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
    if (data.billType == "token") {
      this.productName = `Token Listrik Rp. ${rpstring}`;
    } else if (data.billType == "billing") {
      this.productName = `Tagihan Listrik Rp. ${rpstring}`;
    }
    this.meterId = data.id;
    this.name = customerData.bank_account_name;
    let fee1 = Number(data.amount);
    let fee2 = fee1 + Number(this.adminFee);
    let feestring = fee2
      .toString()
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
    this.totalFee = `Rp. ${feestring}`;
    this.adminFee = this.adminFee.replace(
      /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      "$1\."
    );
  },
};
</script>

<style>
input:focus {
  outline: none;
}
</style>
