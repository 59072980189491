<template>
  <div style="margin: 10px 20px 10px 20px; padding: 5px">
    <img
      src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
      style="max-width: 7%"
      @click="returnBills()"
    />
    <h1>Listrik PLN</h1>
    <br />
    <b-row style="margin: 5px 0; background-color: #f8f8f8">
      <b-col cols="6"
        ><h2 style="color: #3d3d3d">
          <strong>Saldo tersedia</strong>
        </h2></b-col
      >
      <b-col cols="6" style="text-align: right; color: #3d3d3d"
        ><h2>
          <strong
            >Rp.
            {{
              balance.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}</strong
          >
        </h2></b-col
      >
    </b-row>
    <h2 style="color: #5f7af7">Jenis Produk Listrik:</h2>
    <br />
    <b-form-select
      v-model="billType"
      :options="typeOptions"
      style="width: 100%; border: none"
    ></b-form-select>
    <hr style="margin: 0 0 10px 0" />
    <br />
    <h2 style="color: #5f7af7">No meter / ID Pelanggan:</h2>
    <br />
    <input
      v-model="id"
      @input="formatId(id)"
      type="text"
      maxlength="12"
      inputmode="numeric"
      placeholder="Masukkan nomor ID pelanggan"
      style="width: 80%; border: none; padding: 10px"
    />
    <hr style="margin: 0 0 10px 0" />
    <br />
    <div v-if="this.billType == 'token'">
      <h2 style="color: #5f7af7">Nominal:</h2>
      <br />
      <b-form-select
        v-model="amount"
        :options="amountOptions"
        style="width: 100%; border: none"
      ></b-form-select>
      <hr style="margin: 0 0 10px 0" />
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <h3>Transaksi pembelian produk PLN akan diproses maksimal 1x24 jam</h3>
    <button @click="checkoutPLN()" class="continueBtn" style="width: 100%">
      Lanjutkan Pembelian
    </button>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  data() {
    return {
      billType: null,
      id: "",
      amount: null,
      typeOptions: [
        {
          value: null,
          text: "Mohon pilih jenis pembayaran listrik",
          disabled: true,
        },
      ],
      amountOptions: [
        {
          value: null,
          text: "Mohon pilih jumlah pulsa token listrik",
          disabled: true,
        },
        { value: "20000", text: "Rp. 20.000" },
        { value: "50000", text: "Rp. 50.000" },
        { value: "100000", text: "Rp.  100.000" },
        { value: "200000", text: "Rp.  200.000" },
        { value: "500000", text: "Rp.  500.000" },
        { value: "1000000", text: "Rp.  1.000.000" },
        { value: "2000000", text: "Rp.  2.000.000" },
        { value: "5000000", text: "Rp.  5.000.000" },
        { value: "10000000", text: "Rp.  10.000.000" },
      ],
    };
  },
  methods: {
    returnBills() {
      this.$router.push("/Bills");
    },
    checkoutPLN() {
      if (this.billType == null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Mohon pilih jenis tagihan PLN anda",
        });
      } else if (!this.id || this.id.length == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Mohon masukkan nomor pelanggan PLN anda",
        });
      } else if (
        this.billType == "token" &&
        (!this.amount || this.amount.length == 0)
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Mohon masukkan jumlah pulsa token PLN anda",
        });
      } else {
        let data = {
          billType: this.billType,
          id: this.id,
          amount: this.amount,
        };
        this.$store.dispatch("storeMutationPLN", data);
      }
    },
  },
  created() {
    // if (
    //   !this.$store.state.PLNStatus.length ||
    //   this.$store.state.PLNStatus == []
    // ) {
    //   let PPOBStatus = JSON.parse(localStorage.PPOBStatus);
    //   PPOBStatus.forEach((element) => {
    //     if (element.name == "Listrik PLN" && element.is_active) {
    //       axios({
    //         url: `${process.env.VUE_APP_API_URL}/api/v1/ppob/get_ppob/${element.id}`,
    //         method: "get",
    //         headers: {
    //           Authorization: "Bearer " + localStorage.earnAccessToken,
    //         },
    //       })
    //         .then((res) => {
    //           this.$store.dispatch("PLNStatus", res.data.data);
    //           let PLNStatus = this.$store.state.PLNStatus;
    //           for (let i = 0; i < PLNStatus.length; i++) {
    //             if (PLNStatus[i].is_active) {
    //               let PLNData = {};
    //               PLNData.text = PLNStatus[i].name;
    //               if (PLNData.text == "Token Listrik") {
    //                 PLNData.value = "token";
    //               } else if (PLNData.text == "Tagihan Listrik") {
    //                 PLNData.value = "tagihan";
    //               } else if (PLNData.text == "Non-Tagihan Listrik") {
    //                 PLNData.value = "non-tagihan";
    //               }
    //               PLNData.id = PLNStatus[i].id;
    //               this.typeOptions.push(PLNData);
    //             }
    //           }
    //         })
    //         .catch((err) => {
    //           Swal.fire({
    //             icon: "error",
    //             title: "Oops...",
    //             text: err.response.message,
    //           });
    //         });
    //     } else if (element.name == "Listrik PLN" && !element.is_active) {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: "Maaf, saat ini layanan pembayaran listrik PLN sedang di non-aktifkan",
    //       });
    //     }
    //   });
    // }
  },
  computed: {
    balance: function () {
      let limit = this.$store.state.balance;
      if (!limit || limit == 0) {
        limit = JSON.parse(localStorage.earn).balance.current_available_balance;
        this.$store.dispatch("storeBalance", limit);
      }
      return limit;
    },
    formatId() {
      this.id = this.id.replace(/[A-Za-z]/g, "");
    },
  },
};
</script>
