import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import TnC from "../views/TnC.vue";
import Dashboard from "../views/Dashboard.vue";
import Bills from "../views/Bills.vue";
import PLN from "../views/PLN.vue";
import Confirmation from "../views/Confirmation.vue";
import CustomWithdraw from "../views/CustomWithdraw.vue";
import Notification from "../views/Notification.vue";
import History from "../views/History.vue";
import Profile from "../views/Profile.vue";
import NotFound from "../views/NotFound.vue";
import Calendar from "../views/Calendar.vue";
import Maintenance from "../views/Maintenance.vue";
import CheckoutPLN from "../views/CheckoutPLN.vue";
import InvoicePLN from "../views/InvoicePLN.vue";
import InputVoucher from "@/views/InputVoucher.vue";
import ClaimVoucher from "@/views/ClaimVoucher.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/TnC", component: TnC },
  { path: "/Dashboard", component: Dashboard },
  { path: "/Bills", component: Bills },
  { path: "/PLN", component: PLN },
  { path: "/CheckoutPLN", component: CheckoutPLN },
  { path: "/InvoicePLN", component: InvoicePLN },
  { path: "/Confirmation", component: Confirmation },
  { path: "/Notification", component: Notification },
  { path: "/Profile", component: Profile },
  { path: "/History", component: History },
  { path: "/CustomWithdraw", component: CustomWithdraw },
  { path: "/Home", component: Home },
  { path: "/Calendar", component: Calendar },
  { path: "/Maintenance", component: Maintenance },
  { path: "/InputVoucher", component: InputVoucher },
  { path: "/ClaimVoucher", component: ClaimVoucher },
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
