<template>
    <div style="margin: 10px 20px 10px 20px;">
        <img src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095" style="max-width: 7%;" @click="returnDashboard()">
        <h1>Periode Gaji</h1>
        <br>
        <div style="text-align: center;">
            <div v-if="startMonth != endMonth">
                <v-calendar color="blue" :attributes="attrs" :min-date="startDate" :max-date="endDate" :rows="2" :step="0" is-expanded locale="ID"/>
            </div>
            <div v-if="startMonth == endMonth">
                <v-calendar color="blue" :attributes="attrs" :min-date="startDate" :max-date="endDate" is-expanded locale="ID"/>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            startDate: '',
            endDate: '',
            startMonth: '',
            lastDayStartMonth: '',
            endMonth: '',
            firstDayEndMonth: '',
            attrs: [
                {
                    highlight: {
                        start: { fillMode: 'outline' },
                        base: { fillMode: 'light' },
                        end: { fillMode: 'outline' },
                    },
                    dates: { 
                        start: new Date(JSON.parse(localStorage.earn).salary_first_period),
                        end: new Date(JSON.parse(localStorage.earn).salary_last_period)
                    },
                },
                {
                    key: 'today',
                    highlight: {
                        color: 'blue',
                        fillMode: 'solid',
                        },
                    dates: new Date(),
                },
            ],
        };
    },
    created(){
        this.startDate = JSON.parse(localStorage.earn).salary_first_period
        this.endDate = JSON.parse(localStorage.earn).salary_last_period
        this.startMonth = new Date(this.startDate).getMonth()
        this.endMonth = new Date(this.endDate).getMonth()
        this.lastDayStartMonth = new Date (new Date(this.startDate).getFullYear(), this.startMonth, 1)
        this.firstDayEndMonth = new Date (new Date(this.startDate).getFullYear(), this.endMonth+1 , 0)
    },
    methods: {
        returnDashboard(){
            this.$router.push('/Dashboard')
        }
    }
};

</script>

<style>

</style>