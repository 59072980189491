<template>
  <div style="margin: 30px">
    <div style="padding-bottom: 20px">
      <b-row>
        <b-col cols="9">
          <img
            src="https://ik.imagekit.io/44d0h1g5ht2/Atoer/icon-arrowback_9P54mbbhe.png?updatedAt=1701929726095"
            style="max-width: 7%"
            @click="returnDashboard()"
          />
          <h1>Riwayat Pencairan</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Bulan</p>
          <select v-model="month">
            <option disabled value="">Bulan</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </b-col>
        <b-col>
          <p>Tahun</p>
          <select v-model="year">
            <option disabled value="">Tahun</option>
            <option value="2024">2024</option>
          </select>
        </b-col>
        <b-col>
          <button
            @click="findHistory()"
            class="continueBtn"
            style="width: 100%"
          >
            Cari
          </button>
        </b-col>
      </b-row>
    </div>
    <b-list-group-item v-if="historyList.length <= 0">
      <b-row>
        <b-col>
          <h3>Tidak ada riwayat pencairan</h3>
        </b-col>
      </b-row>
    </b-list-group-item>
    <div v-if="historyList.length > 0">
      <div v-for="(history, key) in historyList">
        <div v-if="key % 2 == 0" class="textbox-1">
          <p style="margin: 0">
            <b-row>
              <b-col cols="6" v-if="history.loan_status == `In Process`"
                ><h3 style="color: #111975">
                  <strong>Dalam Proses</strong>
                </h3></b-col
              >
              <b-col
                cols="6"
                v-if="history.loan_status == `Waiting For Approval`"
                ><h3 style="color: #111975">
                  <strong>Menunggu Persetujuan</strong>
                </h3></b-col
              >
              <b-col cols="6" v-if="history.loan_status == `Failed`"
                ><h3 style="color: #e0196a">
                  <strong>Gagal</strong>
                </h3></b-col
              >
              <b-col cols="6" v-if="history.loan_status == `Canceled`"
                ><h3 style="color: #e0196a">
                  <strong>Dibatalkan</strong>
                </h3></b-col
              >
              <b-col cols="6" v-if="history.loan_status == `Success`"
                ><h3 style="color: #00c0b8">
                  <strong>Sukses</strong>
                </h3></b-col
              >
              <b-col cols="6" style="text-align: right"
                ><h3>
                  <strong
                    >Rp.
                    {{
                      Intl.NumberFormat("id-ID").format(
                        history.amount_withdrawal
                      )
                    }}</strong
                  >
                </h3></b-col
              >
            </b-row>
            <b-row>
              <b-col>
                {{ new Date(history.transaction_date).getDate() }}/{{
                  new Date(history.transaction_date).getMonth() + 1
                }}/{{ new Date(history.transaction_date).getFullYear() }}
              </b-col>
              <b-col
                v-if="
                  Object.hasOwn(history, 'transaction_type') == false ||
                  history.transaction_type == 'EWA'
                "
                ><p style="color: #111975; text-align: right">
                  <strong>EWA</strong>
                </p></b-col
              >
            </b-row>
          </p>
        </div>
        <div v-if="key % 2 != 0" class="textbox-2">
          <p style="margin: 0">
            <b-row>
              <b-col cols="6" v-if="history.loan_status == `In Process`"
                ><h3 style="color: #111975">
                  <strong>Dalam Proses</strong>
                </h3></b-col
              >
              <b-col
                cols="6"
                v-if="history.loan_status == `Waiting For Approval`"
                ><h3 style="color: #111975">
                  <strong>Menunggu Persetujuan</strong>
                </h3></b-col
              >
              <b-col cols="6" v-if="history.loan_status == `Failed`"
                ><h3 style="color: #e0196a">
                  <strong>Gagal</strong>
                </h3></b-col
              >
              <b-col cols="6" v-if="history.loan_status == `Canceled`"
                ><h3 style="color: #e0196a">
                  <strong>Dibatalkan</strong>
                </h3></b-col
              >
              <b-col cols="6" v-if="history.loan_status == `Success`"
                ><h3 style="color: #00c0b8">
                  <strong>Sukses</strong>
                </h3></b-col
              >
              <b-col cols="6" style="text-align: right"
                ><p>
                  <strong
                    >Rp.
                    {{
                      Intl.NumberFormat("id-ID").format(
                        history.amount_withdrawal
                      )
                    }}</strong
                  >
                </p></b-col
              >
            </b-row>
            <b-row>
              <b-col>
                {{ new Date(history.transaction_date).getDate() }}/{{
                  new Date(history.transaction_date).getMonth() + 1
                }}/{{ new Date(history.transaction_date).getFullYear() }}
              </b-col>
              <b-col
                cols="6"
                v-if="
                  Object.hasOwn(history, 'transaction_type') == false ||
                  history.transaction_type == 'EWA'
                "
                ><p style="color: #111975; text-align: right">
                  <strong>EWA</strong>
                </p></b-col
              >
            </b-row>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      historyList: [],
      month: 0,
      year: 0,
    };
  },
  methods: {
    returnDashboard() {
      this.$router.push("/Dashboard");
    },
    findHistory() {
      if (this.month == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Mohon pilih bulan riwayat pencairan atau pembelian",
        });
      } else if (this.year == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Mohon pilih tahun riwayat pencairan atau pembelian",
        });
      } else {
        axios({
          url: `${process.env.VUE_APP_API_URL}/api/v1/loan/list_loan`,
          method: "post",
          headers: {
            Authorization: "Bearer " + localStorage.earnAccessToken,
          },
          data: {
            customer_uuid: `${localStorage.earnCustUUID}`,
            year_period: this.year,
            month_period: this.month,
          },
        })
          .then((res) => {
            this.historyList = res.data.data;
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    },
  },
  created() {
    axios({
      url: `${process.env.VUE_APP_API_URL}/api/v1/loan/list_loan`,
      method: "post",
      headers: {
        Authorization: "Bearer " + localStorage.earnAccessToken,
      },
      data: {
        customer_uuid: `${localStorage.earnCustUUID}`,
        year_period: new Date().getFullYear(),
        month_period: new Date().getMonth() + 1,
      },
    })
      .then((res) => {
        this.historyList = res.data.data;
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });
  },
};
</script>

<style>
.textbox-1 {
  background-color: #f8f8f8;
  padding: 10px;
}

.textbox-2 {
  padding: 10px;
}
</style>

<style scoped>
p {
  margin: 0;
}
</style>
