import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {
  BootstrapVue,
  IconsPlugin,
  SidebarPlugin,
  ButtonPlugin,
  VBTogglePlugin,
  LayoutPlugin,
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "./store";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VCalendar from "v-calendar";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(SidebarPlugin);
Vue.use(IconsPlugin);
Vue.use(ButtonPlugin);
Vue.use(VBTogglePlugin);
Vue.use(LayoutPlugin);
Vue.use(VCalendar);
new Vue({
  router,
  Swal,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
